<template>
    <page title="请假申请填写" :hasBack="true" textAlign="left">
        <text-wrapper label="班级名称">
            <select-picker
                title="班级名称"
                :columns="classList"
                type="classId"
                @selectConfirm="selectConfirm"
                :defaultValue="currentChooseClass.name"
            />
        </text-wrapper>
        <text-wrapper label="学生姓名">
            <select-picker
                title="学生姓名"
                :columns="childColumns"
                type="studentUserid"
                @selectConfirm="selectConfirm"
                :defaultValue="formValues.studentName"
            />
        </text-wrapper>
        <text-wrapper label="请假类型">
            <select-picker title="请假类型" :defaultValue="formValues.typeName" :columns="leaveTypeColumns" type="type" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <text-wrapper label="开始时间">
            <time-picker title="开始时间" :defaultValue="formValues.startTimeDefault"  type="startTime" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <text-wrapper label="结束时间">
            <time-picker title="结束时间" :defaultValue="formValues.endTimeDefault" type="endTime" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <text-wrapper label="请假时长">
            <div class="distance-days">{{ distanceDays }}天</div>
        </text-wrapper>
        <text-wrapper label="班主任">
            <select-picker
                title="班主任"
                :columns="teacherColumns"
                type="teacherUserid"
                @selectConfirm="selectConfirm"
                :defaultValue="formValues.teacherName"
            />
        </text-wrapper>
        <common-block class="reason" title="请假理由">
            <van-field
                class="reason-input"
                v-model="reason"
                rows="4"
                autosize
                type="textarea"
                placeholder="请点击输入请假理由"
            />
        </common-block>
        <div class="btn-wrapper">
            <van-button class="confirm-btn" round type="primary" @click="confirmSubmit">确认提交</van-button>
        </div>
        <van-popup  v-model:show="confirmVisible" round position="bottom">
            <div class="detail-wrapper">
                <div class="detail-title has-line">
                    请假详情信息
                </div>
                <div class="detail">
                    <text-wrapper class="has-line" label="请假人">
                        <div class="des">{{ formValues.studentName }}</div>
                    </text-wrapper>
                    <text-wrapper class="has-line" label="请假类型">
                        <div class="des">{{ formValues.typeName }}</div>
                    </text-wrapper>
                    <text-wrapper class="has-line" label="开始时间">
                        <div class="des">{{ formValues.startTimeFormate }}</div>
                    </text-wrapper>
                    <text-wrapper class="has-line" label="结束时间">
                        <div class="des">{{ formValues.endTimeFormate }}</div>
                    </text-wrapper>
                    <text-wrapper class="has-line" label="请假时长">
                        <div class="des">{{ distanceDays }}天</div>
                    </text-wrapper>
                    <text-wrapper class="has-line" label="班主任">
                        <div class="des red">{{ formValues.teacherName }}</div>
                    </text-wrapper>
                    <common-block class="name-wrapper" :border="false" title="请假理由">
                        <div class="detail-reason">
                            {{reason}}
                        </div>
                    </common-block>
                </div>
                <div class="detail-btn">
                    <van-button class="confirm-btn" round type="primary" @click="confirmDetail">确认提交</van-button>
                </div>
            </div>
        </van-popup>
    </page>
</template>
<script>
    import Page from '@/components/page/Page';
    import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
    import SelectPicker from '@/components/selectPicker/SelectPicker.vue';
    import TextWrapper from '@/components/text/text';
    import { reactive, watch, ref } from 'vue';
    import TimePicker from '@/components/timePicker/TimePicker';
    import {Field, Button, Toast, Popup} from 'vant';
    import commonRequest from '@/services';
    import dayjs from 'dayjs';
    import { DATE_TIME_FORMATE } from '@/constants';
    import {useRoute, useRouter} from 'vue-router';
    import {getStorage, setStorage} from "@/utils/storage";

    export default {
        name: 'schoolNotice',
        components: {
            TimePicker,
            [Page.name]: Page,
            [CommonBlock.name]: CommonBlock,
            [SelectPicker.name]: SelectPicker,
            [TextWrapper.name]: TextWrapper,
            [TimePicker.name]: TimePicker,
            [Field.name]: Field,
            [Button.name]: Button,
            [Popup.name]: Popup,
        },
        setup() {
            const router = useRouter();
            const route = useRoute();

            const pageTitle = ref('');
            const confirmVisible = ref(false);
            const formValues = reactive({
                studentUserid: '',
                type: '',
                typeName: '',
                startTime: '',
                endTime: '',
                teacherUserid: '',
            });
            const reason = ref('');
            const classList = ref([]);
            const currentChooseClass = reactive({
                id: '',
                name: ''
            });
            commonRequest({ action: 12009 }).then((res) => {
                if (res.data) {
                    classList.value = res.data.map(item => ({
                        id: item.classId,
                        text: item.className
                    }));
                    if (res.data.length) {
                        const chooseStudentId = getStorage('chooseClassId');
                        if (!chooseStudentId) {
                            currentChooseClass.id = res.data[0].classId;
                            currentChooseClass.name = res.data[0].className;
                            setStorage('chooseClassId', res.data[0].classId);
                        } else {
                            currentChooseClass.id = chooseStudentId;
                            currentChooseClass.name = res.data.find(item => item.classId == chooseStudentId)?.className;
                        }
                    }
                    if (res.data.length) {
                        fetchChildColumns()
                    }
                }
            });

            const childColumns = ref([]);

            const fetchChildColumns = () => {
                commonRequest({ action: 12007, classId:  currentChooseClass.id }).then((res) => {
                    if (res.rows) {
                        childColumns.value = res.rows.map((item) => ({
                            id: item.studentUserid,
                            text: item.studentName,
                        }));
                    }
                });
            };

            const leaveTypeColumns = ref([
            ]);

            commonRequest({ action: 10002, dCode: 'sys_student_leave_type' }).then((res) => {
                if (res.data) {
                    leaveTypeColumns.value = res.data.map((item) => ({
                        id: item.value,
                        text: item.name,
                    }));
                }
            });

            const teacherColumns = ref([]);

            const fetchTeacher = () => {
                commonRequest({ action: 11010, studentUserid: formValues.studentUserid }).then((res) => {
                    if (res.data) {
                        teacherColumns.value = res.data.map((item) => ({
                            id: item.teacherUserid,
                            text: item.teacherName,
                        }));
                        const userId = getStorage('userId');
                        if (userId) {
                            formValues.teacherName = res.data.find(item => item.teacherUserid == userId)?.teacherName;
                            formValues.teacherUserid = userId;
                        }
                    }
                });
            };

            const selectConfirm = ({ type, value }) => {
                formValues[type] = value;
                if (type === 'classId') {
                    currentChooseClass.id = value;
                    fetchChildColumns();
                }
                if (type === 'studentUserid') {
                    fetchTeacher();
                }
            };
            const distanceDays = ref(0);
            watch([formValues], () => {
                if (formValues.startTime && formValues.endTime) {
                    const distanceTIme = new Date(formValues.endTime).getTime() - new Date(formValues.startTime).getTime();
                    const halfDays = 1000 * 60 * 60 * 12;
                    distanceDays.value = Math.ceil(distanceTIme / halfDays) / 2;
                } else {
                    distanceDays.value = 0;
                }
            });
            const confirmSubmit = () => {
                console.log(formValues, 'formValues');
                if (!formValues.studentUserid) {
                    Toast('请选择孩子姓名');
                    return;
                }
                if (!formValues.type) {
                    Toast('请选择请假类型');
                    return;
                }
                if (!formValues.startTime) {
                    Toast('请选择开始时间');
                    return;
                }
                if (!formValues.endTime) {
                    Toast('请选择结束时间');
                    return;
                }
                if (!formValues.endTime) {
                    Toast('请选择结束时间');
                    return;
                }
                if (!formValues.teacherUserid) {
                    Toast('请选择班主任');
                    return;
                }
                if (!reason.value) {
                    Toast('请输入请假理由');
                    return;
                }

                const studentName = childColumns.value.find((item) => item.id === formValues.studentUserid)?.text;
                const teacherName = teacherColumns.value.find((item) => item.id === formValues.teacherUserid)?.text;
                const typeName = leaveTypeColumns.value.find((item) => item.id === formValues.type)?.text;
                formValues.studentName = studentName;
                formValues.teacherName = teacherName;
                formValues.typeName = typeName;
                formValues.startTimeFormate = dayjs(formValues.startTime).format(DATE_TIME_FORMATE);
                formValues.endTimeFormate = dayjs(formValues.endTime).format(DATE_TIME_FORMATE);
                confirmVisible.value = true;

            };
            const confirmDetail = () => {
                const studentName = childColumns.value.find((item) => item.id === formValues.studentUserid)?.text;
                const teacherName = teacherColumns.value.find((item) => item.id === formValues.teacherUserid)?.text;
                const saveMsg = {
                    action: 11004,
                    type: formValues.type,
                    startTime: dayjs(formValues.startTime).format(DATE_TIME_FORMATE),
                    endTime: dayjs(formValues.endTime).format(DATE_TIME_FORMATE),
                    studentUserid: formValues.studentUserid,
                    studentName,
                    teacherUserid: formValues.teacherUserid,
                    teacherName,
                    duration: distanceDays.value,
                    reason: reason.value,
                };
                if (route.query.id) {
                    saveMsg.action = 11007;
                    saveMsg.id = route.query.id;
                }
                commonRequest(saveMsg).then(() => {
                    Toast.success('提交成功');
                    setTimeout(() => {
                        router.replace('/approveList');
                    }, 100);
                });
            }
            return {
                childColumns,
                leaveTypeColumns,
                teacherColumns,
                selectConfirm,
                distanceDays,
                formValues,
                reason,
                confirmSubmit,
                pageTitle,
                currentChooseClass,
                classList,
                confirmDetail,
                confirmVisible
            };
        },
    };
</script>
<style lang="less" scoped>
    .distance-days {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 5px;
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #999999;
    }
    .reason-input {
        padding: 5px 10px 5px 0;
    }
    .btn-wrapper {
        margin-top: 59px;
        text-align: center;
        margin-bottom: 20px;
    }
    .detail-btn {
        text-align: center;
        padding-bottom: 20px;
        flex-shrink: 0;
    }
    .detail-wrapper {
        display: flex;
        flex-direction: column;
        height: 600px;
        /deep/ .text-wrapper {
            margin-bottom: 0;
            border-radius: 0;
        }
    }
    .detail{
        padding-left: 15px;
        flex: 1;
        overflow: auto;
        /deep/ .label {
            font-size: 13px;
            color: #999999;
            width: 64px;
        }
    }
    .detail-title {
        flex-shrink: 0;
        font-size: 18px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #E64141;
        padding: 18px 0;
        text-align: center;
    }
    .detail-reason {
        font-size: 15px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
        padding: 15px 0 15px 0;
        text-indent: 26px;
    }
    .des {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
        flex: 1;
        position: relative;
        &.red {
            color: #FF467F;
        }
        span {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
        }
    }

    .has-line {
        position: relative;
        &::after {
            content: '';
            left: 10px;
            right: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            background: #f2f2f2;
        }
    }
</style>
