<template>
    <div class="select-picker" @click="changePickVisible">
        <div class="placeholder">
            <span class="default" v-if="!defaultValue && !valueData.text">请点击选择</span>
            <span class="value" v-else>{{valueData.text || defaultValue  }}</span>
        </div>
        <img src="@/assets/images/common/select.png" v-if="columns.length" alt="" />
    </div>
    <van-popup ref="vantPicker" v-model:show="pickerVisible" round position="bottom">
        <van-picker
            :title="title"
            :columns="columns"
            @confirm="onConfirm"
            @change="onChange"
            @cancel="changePickVisible"
        />
    </van-popup>
</template>
<script>
import { Picker, Popup } from 'vant';
import { ref, reactive } from 'vue';
export default {
    name: 'SelectPicker',
    components: {
        [Picker.name]: Picker,
        [Popup.name]: Popup,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        defaultValue: String,
    },
    emits: ['selectConfirm', 'selectChange'],
    setup(props, { emit }) {
        const valueData = reactive({ text: '' });
        const changePickVisible = () => {
            if (!props.columns.length) return;
            pickerVisible.value = !pickerVisible.value;
        };
        const onConfirm = (value) => {
            changePickVisible();
            if (!value.length) {
                valueData.text = value.text;
                emit('selectConfirm', { type: props.type, value: value.id });
            } else {
                valueData.text = value.map((item) => item.text).join('/');
                emit('selectConfirm', { type: props.type, value });
            }
        };
        const pickerVisible = ref(false);
        const onChange = (value) => {
            emit('selectChange', { type: props.type, value: value });
        };
        return {
            onConfirm,
            pickerVisible,
            changePickVisible,
            valueData,
            onChange,
        };
    },
};
</script>
<style lang="less" scoped>
.select-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 8px;
    box-sizing: border-box;
    .placeholder {
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #999999;
        /*.default {*/
        /*    font-size: 13px;*/
        /*    font-family: PingFang SC,serif;*/
        /*    font-weight: 400;*/
        /*    color: #999999;*/
        /*}*/
        /*.value {*/
        /*    font-size: 14px;*/
        /*    font-family: PingFang SC,serif;*/
        /*    font-weight: bold;*/
        /*    color: #333333;*/
        /*}*/
    }
    img {
        width: 10px;
    }
}
</style>
